@import url(https://fonts.googleapis.com/css2?family=Aladin&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  font-family: 'Montserrat', sans-serif;
  background-color: black;
  color:white;
}

.logo{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.navbar-brand{
  font-family: Aladin;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5rem;
}

.nav-link:hover{
  color: #CC0000 !important;
}

.outter {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.video-container {
  height: calc(100vh - 5rem);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.video-container video {
  object-fit: cover;
  position: absolute;
  height: calc(100vh - 5rem);
  width: 100%;
  top: 0;
  left: 0;
}

.video-container:after {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}


.callout {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  text-align: center;
  position: relative;
  z-index: 10;
  width: 70%;
  margin: auto;
}

#about {
  padding: 7rem 0 7rem 0;
}

#portfolio {
  padding: 4rem 0 7rem 0;
  /* background-color: #C00F00; */
  /* background-color: #b00f00; */
  background-color: #990d00;
  
}



.portfolio-video video {
  width: 98%;
}

.slick-slide div .portfolio-video {
  padding: 1rem;
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}

.slick-current .portfolio-video {
  -webkit-transform: scale(1.15);
          transform: scale(1.15); 
  transition: -webkit-transform .3s ease-out; 
  transition: transform .3s ease-out; 
  transition: transform .3s ease-out, -webkit-transform .3s ease-out; 
  -webkit-filter: brightness(100%) !important; 
          filter: brightness(100%) !important;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

.red-text {
  color: #C00F00;
  font-weight: bold;
}

.video-link:hover{
  text-decoration:underline;
  color:black !important;
}

.intro-text{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}



.portfolio-image img{
  max-height: 600px;
  margin-right: auto;
  margin-left: auto;
  object-fit: cover;
  height: 40rem;
  width: 98%;
}

.portfolio-text{
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    padding: 0 0 4rem 0;
}
.portfolio-text:before,
.portfolio-text:after {
    content: '';
    border-top: 2px solid;
    margin: 0 20px 0 0;
    -webkit-flex: 1 0 20px;
            flex: 1 0 20px;
}

.portfolio-text:after {
    margin: 0 0 0 20px;
}

.contact-logo-section a {
  padding: 1rem;
}

.contact-logo-section a:hover{
  color:#C00F00 !important;
 
}

